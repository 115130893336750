import React from "react";

const Signin = React.lazy(() => import("./Signin"));
const ForgotPassword = React.lazy(() => import("./ForgotPassword"));
const ResellerRegister = React.lazy(() => import("./ResellerRegister"));
const ResetPassword = React.lazy(() => import("./ResetPassword"));

export const authRouteConfig = [
  {
    path: "/signin",
    element: <Signin />,
  },
  {
    path: "/forget-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reseller-register",
    element: <ResellerRegister />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
];
