import PropTypes from "prop-types";
import { Avatar, List, Typography } from "antd";
import { getTitleContentLog } from "@solidandsoft/utility/Utils";
import avatar from "assets/images/robot-avatar.webp";

const { Paragraph } = Typography;

const LogItem = (props) => {
  const {
    item: { entityId, userId, interacts, createdBy },
    isRead,
  } = props;

  const { title, content } = getTitleContentLog({
    // entityName,
    entityId,
    userId: userId || createdBy,
    interactType: interacts?.type,
  });
  return (
    <List.Item
      className={`notify-listItem item-hover ${isRead ? "" : "item-not-read"}`}
    >
      <List.Item.Meta
        avatar={<Avatar className="notify-message-avatar" src={avatar} />}
        title={<Paragraph ellipsis={{ rows: 2 }}>{title}</Paragraph>}
        description={<Paragraph ellipsis={{ rows: 3 }}>{content}</Paragraph>}
      />
    </List.Item>
  );
};

export default LogItem;

LogItem.propTypes = {
  item: PropTypes.object.isRequired,
  isRead: PropTypes.bool,
};
