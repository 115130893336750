// @ts-nocheck
import React from "react";

const HameDashboard = React.lazy(() => import("./HameDashboard"));

export const dashboardConfig = [
  {
    path: "/dashboards",
    element: <HameDashboard />,
  },
];
