import customMessage from "@solidandsoft/core/AppToast";
import jwtAxios from "@solidandsoft/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@solidandsoft/services/endpoint";
import { useIntl } from "react-intl";
import { useMutation, useQueryClient } from "react-query";

export const editNotification = ({ id, ...rest }) =>
  jwtAxios.patch(
    `${URL_API.CRM_NOTIFICATIONS.PATCH_UPDATE_NOTIFICATION}/${id}`,
    {
      ...rest,
    }
  );

const useEditNotification = () => {
  const queryClient = useQueryClient();
  const { messages } = useIntl();
  return useMutation({
    mutationFn: editNotification,
    onSuccess: () => {
      queryClient.refetchQueries([URL_API.CRM_NOTIFICATIONS.GET_NOTIFICATIONS]);
      queryClient.refetchQueries([URL_API.CRM_NOTIFICATIONS.GET_NOTIFICATION]);
      customMessage.success(
        messages["app.notifications.editNotification.success"]?.toString()
      );
    },
    onError: (error) => {
      customMessage.error(
        error?.message ??
          messages["app.products.editNotification.error"]?.toString()
      );
    },
  });
};

export default useEditNotification;
