import customMessage from "@solidandsoft/core/AppToast";
import jwtAxios from "@solidandsoft/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@solidandsoft/services/endpoint";
import { useIntl } from "react-intl";
import { useMutation, useQueryClient } from "react-query";

export const createNotification = (payload) =>
  jwtAxios.post(URL_API.CRM_NOTIFICATIONS.POST_NOTIFICATION, payload);

const useCreateNotification = () => {
  const queryClient = useQueryClient();
  const { messages } = useIntl();
  return useMutation({
    mutationFn: createNotification,
    onSuccess: () => {
      queryClient.refetchQueries([URL_API.CRM_NOTIFICATIONS.GET_NOTIFICATIONS]);
      customMessage.success(
        messages["app.notifications.createNotification.success"]?.toString()
      );
    },
    onError: (error) => {
      customMessage.error(
        error?.message ??
          messages["app.notifications.createNotification.error"]?.toString()
      );
    },
  });
};

export default useCreateNotification;
