import { URL_API } from "@solidandsoft/services/endpoint";
import jwtAxios from "@solidandsoft/services/auth/jwt-auth/jwt-api";
import { useInfiniteQuery } from "react-query";
import { useMemo } from "react";

const getMyLogs = (params) =>
  jwtAxios.get(URL_API.CRM_NOTIFICATIONS.GET_MY_LOGS, { params });

const useGetMyLogsInfinite = (params) => {
  const { data, ...rest } = useInfiniteQuery(
    [URL_API.CRM_NOTIFICATIONS.GET_MY_LOGS, "infinite", params],
    (previous) =>
      getMyLogs({
        ...params,
        page: previous?.pageParam ?? 1,
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        const { page, take, totalCount } = lastPage?.meta ?? {};
        const hasNextPage = Number(page) * Number(take) < Number(totalCount);
        return hasNextPage ? pages.length + 1 : undefined;
      },
    }
  );

  const renderData = useMemo(() => data?.pages?.flatMap((e) => e.data), [data]);

  return {
    data: renderData ?? [],
    loading: rest.isLoading || rest.isFetching,
    ...rest,
  };
};

export default useGetMyLogsInfinite;
