import { useMemo } from "react";

import PropTypes from "prop-types";
import { List, Avatar, Typography } from "antd";
import "./NotificationItem.less";
import { convertToS3Link } from "@solidandsoft/utility/Utils";
import { S3Type } from "shared/constants/AppEnums";
const { Paragraph } = Typography;

const NotificationItem = (props) => {
  const {
    item: { title, content, createdUser },
    isRead,
  } = props;

  const { id, s3Profile, fullName = "" } = createdUser;
  const avatar = useMemo(
    () => convertToS3Link(S3Type.UID_AVATAR, id, s3Profile),
    [id, s3Profile]
  );

  return (
    <List.Item
      className={`notify-listItem item-hover ${isRead ? "" : "item-not-read"}`}
    >
      <List.Item.Meta
        avatar={
          s3Profile ? (
            <Avatar
              className="notify-message-avatar"
              src={avatar}
              alt={title}
            />
          ) : (
            <Avatar className="notify-message-avatar">
              {fullName?.charAt(0)?.toUpperCase()}
            </Avatar>
          )
        }
        title={<Paragraph ellipsis={{ rows: 2 }}>{title}</Paragraph>}
        description={<Paragraph ellipsis={{ rows: 3 }}>{content}</Paragraph>}
      />
    </List.Item>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
  isRead: PropTypes.bool,
};
