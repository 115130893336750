export const authRole = {
  admin: ["user", "admin"],
  user: ["user"],
};

export const RoutePermittedRole = {
  admin: "admin",
  user: "user",
};

export const ThemeStyle = {
  MODERN: "modern",
  STANDARD: "standard",
};

export const ImportExportType = {
  PRODUCTS: "PRODUCTS",
  ADDONS: "ADDONS",
  RANKING: "RANKING",
  GRAPH: "GRAPH",
  ORDER: "ORDER",
};

export const ThemeMode = {
  LIGHT: "light",
  SEMI_DARK: "semi-dark",
  DARK: "dark",
};
export const LayoutType = {
  FULL_WIDTH: "full-width",
  BOXED: "boxed",
  FRAMED: "framed",
};

export const MenuStyle = {
  DEFAULT: "default",
  STANDARD: "standard",
  ROUNDED: "rounded",
  ROUNDED_REVERSE: "rounded-reverse",
  CURVED_MENU: "curved-menu",
};

export const NavStyle = {
  DEFAULT: "default",
  MINI: "mini",
  MINI_SIDEBAR_TOGGLE: "mini-sidebar-toggle",
  STANDARD: "standard",
  HEADER_USER: "user-header",
  HEADER_USER_MINI: "user-mini-header",
  DRAWER: "drawer",
  BIT_BUCKET: "bit-bucket",
  H_DEFAULT: "h-default",
  HOR_HEADER_FIXED: "hor-header-fixed",
  HOR_DARK_LAYOUT: "hor-dark-layout",
  NONE: "none",
};
export const FooterType = {
  FIXED: "fixed",
  FLUID: "fluid",
};
export const ThemeDirection = {
  RTL: "rtl",
  LTR: "ltr",
};

export const Fonts = {
  LIGHT: "300",
  REGULAR: "400",
  MEDIUM: "500",
  BOLD: "600",
  EXTRA_BOLD: "600",
};

export const S3Type = {
  BLOG: "blog",
  UID_AVATAR: "uid_avt",
  BANNER: "banners",
  PRODUCT: "product",
  ORDER: "order",
  WSID_FAVICON: "wsid_favicon",
  WSID_LOGO: "wsid_logo",
  WSID_FAVICON_MAC: "wsid_favicon_mac",
  WSID_LOGO_MAC: "wsid_logo_mac",
  REVIEW: "review",
  PRODUCT_STOCK: "product_stock",
  USER_ID: "user_id",
  BLOG_ID: "blog_id",
};

export const BannerMediaType = {
  IMAGE: "image",
  IMAGE_DETAIL: "imageDetail",
  VIDEO: "video",
};

export const AppInputType = {
  TEXT: "text",
  TEXT_AREA: "text_area",
  NUMBER: "number",
  PASSWORD: "password",
  SELECT: "select",
  UPLOAD_SINGLE_IMAGE: "uploadSingleImage",
  UPLOAD_MULTI_IMAGE: "uploadMultiImage",
  EDITOR: "editor",
  CHECKBOX: "checkbox",
  MULTISELECT: "multiselect",
  MULTISELECT_WITH_DATA: "multiselectWithData",
  SELECT_WITH_DATA: "selectWithData",
  DATE_PICKER: "datePicker",
  DATE_TIME_PICKER: "dateTimePicker",
  TIME_PICKER: "timePicker",
  RATING: "rating",
  RADIO: "radio",
  AUTO_COMPLETE: "autoComplete",
  BUTTON: "button",
  TREE_SELECT: "treeSelect",
  TREE_SELECT_DISPLAY_LIST: "treeSelectDisplayList",
  SELECT_DISPLAY_LIST: "selectDisplayList",
  PHONE_NUMBER_INPUT: "phoneNumberInput",
  FILE_UPLOAD: "fileUpload",
};

export const SortType = {
  ASC: "ASC",
  DESC: "DESC",
};

export const PERMISSIONS = {
  CREATE_RESELLER: "create-reseller",
  EDIT_RESELLER: "edit-reseller",
  CANCEL_RESELLER: "cancel-reseller",

  CREATE_TICKET: "create-ticket",
  EDIT_TICKET: "edit-ticket",
  DELETE_TICKET: "delete-ticket",

  CREATE_LEAD: "create-lead",
  EDIT_LEAD: "edit-lead",
  DELETE_LEAD: "delete-lead",

  CREATE_ROLE: "create-role",
  EDIT_ROLE: "edit-role",
  DELETE_ROLE: "delete-role",

  VIEW_ALL_PRODUCT: "view-all-product",
  VIEW_BASE_PRODUCT: "view-base-product",
  CREATE_PRODUCT: "create-product",
  EDIT_PRODUCT: "edit-product",
  DELETE_PRODUCT: "delete-product",

  VIEW_ALL_ADD_ON: "view-all-addon",
  VIEW_BASE_ADD_ON: "view-base-addon",
  CREATE_ADD_ON: "create-add-on",
  EDIT_ADD_ON: "edit-add-on",
  DELETE_ADD_ON: "delete-add-on",

  CREATE_USER: "create-user",
  EDIT_USER: "edit-user",
  DELETE_USER: "delete-user",

  CREATE_NOTIFICATION: "create-notification",
  EDIT_NOTIFICATION: "edit-notification",
  DELETE_NOTIFICATION: "delete-notification",

  CREATE_ORDER: "create-order",
  EDIT_ORDER: "edit-order",
  DELETE_ORDER: "delete-order",
  APPROVE_REJECT_ORDER: "approve-reject-order",
  VERIFY_ORDER: "verify-order",

  UPDATE_PASSWORD: "update-password",
  UPDATE_SETTING: "update-setting",

  CREATE_DISCOUNT: "create-discount",

  CREATE_NEWS: "create-news",
  EDIT_NEWS: "edit-news",
  DELETE_NEWS: "delete-news",

  IMPORT_EXCEL: "import-excel",
  EXPORT_EXCEL: "export-excel",
};

export const GenderEnum = {
  MALE: "Nam",
  FEMALE: "Nữ",
  OTHER: "Khác",
};

export const GENDERS = [
  {
    id: 0,
    name: GenderEnum.MALE,
    key: "MALE",
  },
  {
    id: 1,
    name: GenderEnum.FEMALE,
    key: "FEMALE",
  },
  {
    id: 2,
    name: GenderEnum.OTHER,
    key: "OTHER",
  },
];

export const UNIT_TYPES = ["PERCENT", "MONEY"];
export const COUPON_TYPES_ENUM = {
  QUANTITY: "QUANTITY",
  CREDIT: "CREDIT",
};

export const AppSearchColumn = {
  DATETIME: "DATE_TIME",
  INPUT_RANGE: "INPUT_RANGE",
  LIST_ITEM: "LIST_ITEM",
  INPUT_NUMBER: "INPUT_NUMBER",
};

export const TIME_RANGE_FILER_ENUM = {
  THIS_WEEK: "THIS_WEEK",
  LAST_WEEK: "LAST_WEEK",
  THIS_MONTH: "THIS_MONTH",
  LAST_MONTH: "LAST_MONTH",
  THIS_YEAR: "THIS_YEAR",
  LAST_YEAR: "LAST_YEAR",
  CUSTOM: "CUSTOM",
};

export const PAYMENT_METHOD_TYPE = [
  {
    name: "Authorize.Net",
    value: "Authorize.Net",
  },
  {
    name: "Cheque",
    value: "Cheque",
  },
  {
    name: "Zelle",
    value: "Zelle",
  },
  {
    name: "External Terminal",
    value: "External Terminal",
  },
  {
    name: "Other",
    value: "Other",
  },
];

export const APP_FORM_MODAL_TYPE = {
  DEFAULT: "DEFAULT",
  TAB: "TAB",
  TWO_COLUMN: "TWO_COLUMN",
};

export const CARD_FORM_ITEM_STATUS = {
  CREATE: "CREATE",
  PREVIEW: "PREVIEW",
  VIEW: "VIEW",
  CHANGE_CARD: "CHANGE_CARD",
};

export const AppAnimates = {
  SLIDEUPIN: {
    hidden: {
      y: "100vh",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.2,
      },
    },
  },
  SLIDEUPOUT: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 0,
      y: "100vh",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDEDOWNIN: {
    hidden: {
      opacity: 0,
      y: "-100vh",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDEDOWNOUT: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 0,
      y: "-100vh",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDELEFTIN: {
    hidden: {
      x: "100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDELEFTOUT: {
    hidden: {
      x: 0,
    },
    visible: {
      opacity: 0,
      x: "100vw",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDERIGHTIN: {
    hidden: {
      x: "-100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDERIGHTOUT: {
    hidden: {
      x: 0,
    },
    visible: {
      opacity: 0,
      x: "-100vw",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  FADEIN: {
    hidden: {
      opacity: 0,
      scale: 1,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        ease: "easeIn",
        when: "beforeChildren",
        staggerChildren: 0.15,
      },
    },
  },
  FADEOUT: {
    hidden: {
      opacity: 1,
      scale: 1,
    },
    visible: {
      opacity: 0,
      scale: 1,
      transition: {
        ease: "easeOut",
        when: "beforeChildren",
        staggerChildren: 0.15,
      },
    },
  },
};

export const USER_PROFILE_AVATAR_ACTION = {
  NONE: "NONE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export const MANAGE_PRODUCT_STOCK_FIELD_TYPE = {
  NUMBER: "NUMBER",
  IMAGE: "IMAGE",
  CHECKBOX: "CHECKBOX",
};

export const ANTD_TABLE_ON_CHANGE_ACTION = {
  PAGINATE: "paginate",
  SORT: "sort",
  FILTER: "filter",
};
export const BadgeStatus = {
  Success: "success",
  Error: "error",
  Default: "default",
  Processing: "processing",
  Warning: "warning",
};

export const PO_STATUS = {
  CREATING: "creating",
  CREATING_VERIFIED: "creating_verified",
  MODIFYING: "modifying",
  MODIFYING_VERIFIED: "modifying_verified",
  PROCESSING: "processing",
  CANCELLED: "cancelled",
  ACTIVE: "active",
  REJECTED: "rejected",
  EXPIRED: "expired",
  DRAFT: "draft",
};

export const NOTIFICATION_TRACKING_KEY = "notification_tracking_key";

export const INTERACT_TYPE = {
  CREATE_POTENTIAL_RESELLER: "CREATE_POTENTIAL_RESELLER",
  CREATE_TICKET: "CREATE_TICKET",
  APPROVE_LEAD: "APPROVE_LEAD",
  REJECT_LEAD: "REJECT_LEAD",
  CREATE_LEAD: "CREATE_LEAD",
};
