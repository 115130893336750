import jwtAxios from "@solidandsoft/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@solidandsoft/services/endpoint";
import { useMutation, useQueryClient } from "react-query";

export const seenNotification = (userInteractIds) =>
  jwtAxios.post(URL_API.CRM_NOTIFICATIONS.SEEN_NOTIFICATIONS, {
    userInteractIds,
  });

const useSeenNotification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: seenNotification,
    onSuccess: () => {
      queryClient.refetchQueries([URL_API.CRM_NOTIFICATIONS.GET_NOTIFICATIONS]);
      queryClient.refetchQueries([URL_API.CRM_NOTIFICATIONS.GET_MY_LOGS]);
      queryClient.refetchQueries([URL_API.CRM_NOTIFICATIONS.GET_UNREAD]);
    },
  });
};

export default useSeenNotification;
