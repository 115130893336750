import { List, Button, Dropdown, Menu, Tabs, Tooltip, Badge } from "antd";
import { IoIosNotificationsOutline } from "react-icons/io";
import AppScrollbar from "../AppScrollbar";
import IntlMessages from "../../utility/IntlMessages";
import NotificationItem from "./NotificationItem";
import "./index.style.less";
import {
  useGetMyLogsInfinite,
  useGetNotificationsInfinite,
  useSeenNotification,
} from "hooks/apis/notifications";
import {
  BellOutlined,
  EyeOutlined,
  NodeIndexOutlined,
} from "@ant-design/icons";
import { useCallback, useMemo, useRef, useState } from "react";
import AppCustomModalWrapper from "../AppCustomModalWrapper";
import ViewNotiModal from "pages/crm/Notifications/ViewNotiModal";
import { useAuthUser } from "@solidandsoft/utility/AuthHooks";
import { RoleIdEnum } from "pages/crm/PurchasingOrder/constant";
import LogItem from "./LogItem";
import useGetUnReadNoti from "hooks/apis/notifications/useGetUnReadNoti";

const AppNotifications = () => {
  const { user } = useAuthUser();
  const {
    data: listNoti,
    fetchNextPage: fetchNextPageNoti,
    hasNextPage: hasNextPageNoti,
    isLoading: isLoadingNoti,
  } = useGetNotificationsInfinite({
    page: 1,
    take: 10,
  });

  const {
    data: listLogs,
    fetchNextPage: fetchNextPageLog,
    hasNextPage: hasNextPageLog,
    isLoading: isLoadingLog,
  } = useGetMyLogsInfinite({ page: 1, take: 10 });
  const { mutate: mutateSeenNotiLogs } = useSeenNotification();
  const unreadNoti = useGetUnReadNoti();

  const [currentId, setCurrentId] = useState(null);
  const viewNotiModalRef = useRef();

  const handleViewAllNoti = useCallback(() => {
    const notiIds = listNoti?.map((item) => item.userInteracts?.[0]?.id) ?? [];
    const logIds =
      listLogs
        ?.filter((item) => item.userInteractSeens?.length === 0)
        .map((item) => item.id) ?? [];
    mutateSeenNotiLogs([...notiIds, ...logIds]);
  }, [listNoti, mutateSeenNotiLogs, listLogs]);
  const isAdmin = user?.roleId === RoleIdEnum.SUPER_ADMIN;
  const menuItems = useMemo(
    () => [
      ...(!isAdmin
        ? [
            {
              key: "1",
              label: (
                <>
                  <BellOutlined /> <IntlMessages id="common.notifications" />
                </>
              ),
              children: (
                <AppScrollbar className="notify-scroll-submenu">
                  <List
                    className="notify-list"
                    dataSource={listNoti}
                    renderItem={(item) => {
                      const isRead =
                        !!item?.userInteracts?.[0]?.userInteractSeens?.length;
                      return (
                        <div
                          key={item.id}
                          onClick={() => {
                            if (!isRead) {
                              mutateSeenNotiLogs([item.userInteracts?.[0]?.id]);
                            }
                            setCurrentId(item.id);
                            viewNotiModalRef?.current?.openModal();
                          }}
                        >
                          <NotificationItem item={item} isRead={isRead} />
                        </div>
                      );
                    }}
                  />
                  {hasNextPageNoti && (
                    <Button
                      type="primary"
                      className="notify-btn-all"
                      loading={isLoadingNoti}
                      onClick={() => fetchNextPageNoti()}
                    >
                      <IntlMessages id="common.seeMore" />
                    </Button>
                  )}
                </AppScrollbar>
              ),
            },
          ]
        : []),
      {
        key: "2",
        label: (
          <>
            <NodeIndexOutlined /> <IntlMessages id="common.system" />
          </>
        ),
        children: (
          <AppScrollbar className="notify-scroll-submenu">
            <AppScrollbar className="notify-scroll-submenu">
              <List
                className="notify-list"
                dataSource={listLogs}
                renderItem={(item) => {
                  const isRead = !!item?.userInteractSeens?.length;
                  return (
                    <div
                      key={item.id}
                      onClick={() => {
                        if (!isRead) {
                          mutateSeenNotiLogs([item?.id]);
                        }
                      }}
                    >
                      <LogItem item={item} isRead={isRead} />
                    </div>
                  );
                }}
              />
              {hasNextPageLog && (
                <Button
                  type="primary"
                  className="notify-btn-all"
                  loading={isLoadingLog}
                  onClick={() => fetchNextPageLog()}
                >
                  <IntlMessages id="common.seeMore" />
                </Button>
              )}
            </AppScrollbar>
          </AppScrollbar>
        ),
      },
    ],
    [
      fetchNextPageLog,
      fetchNextPageNoti,
      hasNextPageLog,
      hasNextPageNoti,
      isAdmin,
      isLoadingLog,
      isLoadingNoti,
      listLogs,
      listNoti,
      mutateSeenNotiLogs,
    ]
  );

  const menu = useMemo(
    () => (
      <Menu selectable={false} className="notify-header-message">
        <Tabs
          tabBarExtraContent={
            <Tooltip title={<IntlMessages id="common.markViewAll" />}>
              <Button
                onClick={handleViewAllNoti}
                type="text"
                icon={<EyeOutlined />}
              />
            </Tooltip>
          }
          defaultActiveKey="1"
          tabBarStyle={{ marginLeft: 10 }}
          items={menuItems}
        />
      </Menu>
    ),
    [handleViewAllNoti, menuItems]
  );
  return (
    <>
      <Dropdown overlay={menu} className="dropdown">
        <Badge count={unreadNoti?.data?.[0]?.totalUnread} offset={[-6, 5]}>
          <a className="notify-link" onClick={(e) => e.preventDefault()}>
            <span className="notify-icon">
              <IoIosNotificationsOutline />
            </span>
            <span className="notify-text">
              <IntlMessages id="common.notifications" />
            </span>
          </a>
        </Badge>
      </Dropdown>
      <AppCustomModalWrapper ref={viewNotiModalRef}>
        <ViewNotiModal id={currentId} />
      </AppCustomModalWrapper>
    </>
  );
};

export default AppNotifications;
