import IntlMessages from "@solidandsoft/utility/IntlMessages";
import AppViewModal from "@solidandsoft/core/AppViewModal";
import { forwardRef } from "react";
import useGetViewNotiFieldConfig from "./useGetViewNotiFieldConfig";
import { useGetNotificationById } from "hooks/apis/notifications";

const ViewNotiModal = forwardRef(({ id }, ref) => {
  const { data } = useGetNotificationById(id);
  const viewNotiFieldConfig = useGetViewNotiFieldConfig();

  return (
    <>
      <AppViewModal
        ref={ref}
        data={data}
        title={<IntlMessages id="app.notifications.viewDetail" />}
        fieldsConfig={viewNotiFieldConfig}
        footer={null}
      />
    </>
  );
});

export default ViewNotiModal;
