import {
  AlertOutlined,
  AppstoreOutlined,
  BellOutlined,
  ContainerOutlined,
  FileTextOutlined,
  HddOutlined,
  SettingOutlined,
  ShoppingOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { IoNewspaperOutline } from "react-icons/io5";
import { MdOutlineDiscount } from "react-icons/md";
import { PERMISSIONS } from "shared/constants/AppEnums";

const routesConfig = [
  {
    id: "dashboards",
    messageId: "sidebar.dashboards",
    type: "group",
    children: [
      {
        id: "dashboards",
        title: "Dashboards",
        messageId: "sidebar.dashboards",
        icon: <AppstoreOutlined />,
        path: "/dashboards",
      },
    ],
  },
  {
    id: "crm",
    title: "CRM",
    messageId: "sidebar.crm",
    type: "group",
    children: [
      {
        id: "products",
        title: "Products",
        messageId: "sidebar.crm.products",
        icon: <ShoppingOutlined />,
        type: "collapse",
        path: "/crm/products",
        scopes: [
          PERMISSIONS.VIEW_BASE_PRODUCT,
          PERMISSIONS.VIEW_ALL_PRODUCT,
          PERMISSIONS.CREATE_PRODUCT,
          PERMISSIONS.EDIT_PRODUCT,
          PERMISSIONS.DELETE_PRODUCT,
        ],
        children: [
          {
            id: "add-ons",
            title: "Add-ons",
            messageId: "sidebar.crm.addOns",
            icon: <HddOutlined />,
            path: "/crm/products/add-ons",
            scopes: [
              PERMISSIONS.VIEW_BASE_ADD_ON,
              PERMISSIONS.VIEW_ALL_ADD_ON,
              PERMISSIONS.CREATE_ADD_ON,
              PERMISSIONS.EDIT_ADD_ON,
              PERMISSIONS.DELETE_ADD_ON,
            ],
          },
        ],
      },
      {
        id: "reseller-registrations",
        title: "Reseller Registrations",
        messageId: "sidebar.crm.registrations",
        icon: <UserOutlined />,
        path: "/crm/reseller-registrations",
        scopes: [
          PERMISSIONS.CREATE_RESELLER,
          PERMISSIONS.EDIT_RESELLER,
          PERMISSIONS.CANCEL_RESELLER,
        ],
      },
      {
        id: "purchasing-order",
        title: "Order",
        messageId: "sidebar.crm.purchasingOrders",
        icon: <FileTextOutlined />,
        path: "/crm/purchasing-order",
        scopes: [
          PERMISSIONS.CREATE_ORDER,
          PERMISSIONS.EDIT_ORDER,
          PERMISSIONS.DELETE_ORDER,
          PERMISSIONS.VERIFY_ORDER,
        ],
      },
      {
        id: "leads",
        title: "Leads",
        messageId: "sidebar.crm.leads",
        icon: <ContainerOutlined />,
        path: "/crm/leads",
        scopes: [
          PERMISSIONS.EDIT_LEAD,
          PERMISSIONS.CREATE_LEAD,
          PERMISSIONS.DELETE_LEAD,
        ],
      },
      {
        id: "tickets",
        title: "Tickets",
        messageId: "sidebar.crm.tickets",
        icon: <AlertOutlined />,
        path: "/crm/tickets",
        scopes: [
          PERMISSIONS.EDIT_TICKET,
          PERMISSIONS.CREATE_TICKET,
          PERMISSIONS.DELETE_TICKET,
        ],
      },
      {
        id: "notifications",
        title: "Notifications",
        messageId: "sidebar.crm.notifications",
        icon: <BellOutlined />,
        path: "/crm/notifications",
        scopes: [
          PERMISSIONS.CREATE_NOTIFICATION,
          PERMISSIONS.EDIT_NOTIFICATION,
          PERMISSIONS.DELETE_NOTIFICATION,
        ],
      },
      // {
      //   id: "logs",
      //   title: "Logs",
      //   messageId: "sidebar.crm.interactions",
      //   icon: <NodeIndexOutlined />,
      //   path: "/crm/logs",
      //   scopes: [],
      // },
      {
        id: "users",
        title: "Users",
        messageId: "sidebar.crm.users",
        icon: <UserOutlined />,
        path: "/crm/users",
        scopes: [
          PERMISSIONS.CREATE_USER,
          PERMISSIONS.EDIT_USER,
          PERMISSIONS.DELETE_USER,
        ],
      },
      {
        id: "discounts",
        title: "Discounts",
        messageId: "sidebar.crm.discounts",
        icon: <MdOutlineDiscount />,
        path: "/crm/discounts",
        scopes: [PERMISSIONS.CREATE_DISCOUNT],
      },
      {
        id: "news",
        title: "News",
        messageId: "sidebar.crm.news",
        icon: <IoNewspaperOutline />,
        path: "/crm/news",
        scopes: [
          PERMISSIONS.CREATE_NEWS,
          PERMISSIONS.EDIT_NEWS,
          PERMISSIONS.DELETE_NEWS,
        ],
      },
    ],
  },
  {
    id: "system",
    messageId: "sidebar.systems",
    type: "group",
    children: [
      {
        id: "roles",
        title: "Roles",
        messageId: "sidebar.systems.roles",
        icon: <TeamOutlined />,
        path: "/systems/roles",
        scopes: [
          PERMISSIONS.CREATE_ROLE,
          PERMISSIONS.EDIT_ROLE,
          PERMISSIONS.DELETE_ROLE,
        ],
      },
      {
        id: "settings",
        title: "Settings",
        messageId: "sidebar.systems.settings",
        icon: <SettingOutlined />,
        path: "/systems/settings",
        scopes: [PERMISSIONS.UPDATE_SETTING],
      },
    ],
  },
];
export default routesConfig;
