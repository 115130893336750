import customMessage from "@solidandsoft/core/AppToast";
import jwtAxios from "@solidandsoft/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@solidandsoft/services/endpoint";
import { useIntl } from "react-intl";
import { useMutation, useQueryClient } from "react-query";

export const deleteNotifications = ({ ids }) =>
  jwtAxios.delete(URL_API.CRM_NOTIFICATIONS.DELETE_NOTIFICATIONS, {
    data: { notificationIds: ids },
  });

const useDeleteNotifications = () => {
  const queryClient = useQueryClient();
  const { messages } = useIntl();
  return useMutation({
    mutationFn: deleteNotifications,
    onSuccess: () => {
      queryClient.refetchQueries([URL_API.CRM_NOTIFICATIONS.GET_NOTIFICATIONS]);
      customMessage.success(
        messages["app.notifications.deleteNotification.success"]?.toString()
      );
    },
    onError: (error) => {
      customMessage.error(
        error?.message ??
          messages["app.notifications.deleteNotification.error"]?.toString()
      );
    },
  });
};

export default useDeleteNotifications;
