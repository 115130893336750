import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import jwtAxios, { setAuthToken } from "./jwt-api";
import { URL_API } from "../../endpoint";
import { parseJwt } from "../../../utility/Utils";
import { useQueryClient } from "react-query";
import customMessage from "@solidandsoft/core/AppToast";
import { APPROVAL_POTENTIAL_STATUS } from "shared/constants/AppConst";
import { useLayoutActionsContext } from "@solidandsoft/utility/AppContextProvider/LayoutContextProvider";
import { NavStyle } from "shared/constants/AppEnums";

const JWTAuthContext = createContext(null);
const JWTAuthActionsContext = createContext(null);

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({ children }) => {
  const { updateNavStyle } = useLayoutActionsContext();

  const queryClient = useQueryClient();
  const [firebaseData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
    isApproved: false,
    reason: "",
    currentStatus: "",
  });

  const getAuthUser = useCallback(async () => {
    const token = localStorage.getItem("cms.token");

    if (!token) {
      setJWTAuthData({
        user: undefined,
        isLoading: false,
        isAuthenticated: false,
        isApproved: false,
        reason: "",
        currentStatus: "",
      });
      return;
    }
    setAuthToken(token);
    const payload = parseJwt(token);
    jwtAxios
      .get(URL_API.USER_INFORMATION, {
        params: {
          id: payload?.user?.id,
        },
      })
      .then((data) => {
        if (data)
          jwtAxios
            .get(URL_API.ROLE.GET_ROLE, {
              params: {
                id: data?.roleId,
              },
            })
            .then(({ permissionRoles }) => {
              if (data?.isApproved) {
                updateNavStyle(NavStyle.DEFAULT);
              } else {
                updateNavStyle(NavStyle.NONE);
              }

              setJWTAuthData({
                user: { ...data, permissionRoles },
                isLoading: false,
                isAuthenticated: true,
                isApproved: data?.isApproved,
                reason: data?.reason,
                currentStatus: data?.currentStatus,
              });
            });
        else {
          throw new Error("User not found");
        }
      })
      .catch(() =>
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
          isApproved: false,
          reason: "",
          currentStatus: "",
        })
      );
  }, [updateNavStyle]);
  useEffect(() => {
    getAuthUser();
  }, [getAuthUser]);

  const signInUser = async ({ email, password, isRememberMe }) => {
    try {
      const { access_token } = await jwtAxios.post(
        URL_API.AUTHENTICATION.LOGIN,
        {
          email,
          password,
          isRememberMe,
        }
      );
      setAuthToken(access_token);
      const payload = parseJwt(access_token);
      const data = await jwtAxios.get(URL_API.USER_INFORMATION, {
        params: {
          id: payload?.user?.id,
        },
      });
      const role = await jwtAxios.get(URL_API.ROLE.GET_ROLE, {
        params: {
          id: data?.roleId,
        },
      });
      const permissionRoles = role?.permissionRoles;

      if (data?.isApproved) {
        updateNavStyle(NavStyle.DEFAULT);
      } else {
        updateNavStyle(NavStyle.NONE);
      }

      setJWTAuthData({
        user: { ...data, permissionRoles },
        isAuthenticated: true,
        isLoading: false,
        isApproved: data?.isApproved,
        reason: data?.reason,
        currentStatus: data?.currentStatus,
      });
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
        isApproved: false,
        reason: "",
        currentStatus: "",
      });
      customMessage.error(error.message);
    }
  };

  const signUpUser = async ({ name, email, password }) => {
    try {
      const { data } = await jwtAxios.post("users", { name, email, password });
      localStorage.setItem("token", data.token);
      setAuthToken(data.token);
      const res = await jwtAxios.get("/auth");
      setJWTAuthData({
        user: res.data,
        isAuthenticated: true,
        isLoading: false,
        isApproved: false,
        reason: "",
        currentStatus: APPROVAL_POTENTIAL_STATUS.PROCESSING,
      });
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
        isApproved: false,
        reason: "",
        currentStatus: "",
      });
    }
  };

  const logout = async () => {
    localStorage.removeItem("token");
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
      isApproved: false,
      reason: "",
      currentStatus: "",
    });
    queryClient.resetQueries();
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
        getAuthUser,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
