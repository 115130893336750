import CryptoJS from "crypto-js";
import moment from "moment";
import {
  APP_UPLOAD_FILE_MIME_TYPES,
  DEFAULT_COLUMN_SORT,
  PageDefault,
} from "shared/constants/AppConst";
import { GENDERS, PO_STATUS, SortType } from "../../shared/constants/AppEnums";
import UserInfoItem from "../core/AppViewItems/UserActionInfoItem/UserInfoItem";
import { Badge } from "antd";
import IntlMessages from "./IntlMessages";
import relativeTime from "dayjs/plugin/relativeTime";
import en from "dayjs/locale/en";
import vi from "dayjs/locale/vi";
import dayjs from "dayjs";

dayjs.extend(relativeTime);

export const formatRelativeDate = (date, locale = "en") => {
  const dayjsLocale = locale === "en" ? en : vi;
  dayjs.locale(dayjsLocale);
  return dayjs(date).fromNow();
};

export const convertHtmlToText = (content) => {
  if (!content) return "";
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");

  return doc
    ?.querySelector("body")
    ?.innerHTML?.replace(/<\/?[^>]+(>|$)/g, " ")
    ?.replace(/\s+/g, " ")
    ?.trim()
    ?.toString();
};

export const formatNumber = (number, locale = "vi-VN") => {
  return Number(number) ? new Intl.NumberFormat(locale).format(number) : 0;
};

export const checkPermissionGranted = ({ permissions = [], scopes = [] }) => {
  return permissions.some(
    (permission) =>
      !scopes || scopes.length === 0 || scopes.includes(permission)
  );
};

export const filterPermissionGrantedList = ({
  permissions = [],
  listItems = [],
}) => {
  return listItems.filter((item) =>
    checkPermissionGranted({ permissions, scopes: item.scopes })
  );
};

export const convertToS3Link = (type, id, s3String) => {
  if (!s3String) return null;
  return `${process.env.REACT_APP_LINK_S3}/${type}${
    id ? `_${id}` : ""
  }/${s3String}`;
};

export const convertBannerUrlToS3Link = (
  typeUser,
  userId,
  typeBlog,
  blogId,
  s3String
) => {
  if (!s3String) return null;
  return `${process.env.REACT_APP_LINK_S3}/${typeUser}${
    userId ? `_${userId}` : ""
  }_${typeBlog}${blogId ? `_${blogId}` : ""}/${s3String}`;
};

export const reloadImg = async (url) => {
  await fetch(url, { cache: "reload", mode: "no-cors" });
  document.body
    .querySelectorAll(`img[src='${url}']`)
    .forEach((img) => (img.src = url));
};

export const convertDateTime = (timeStampFormat) => {
  return timeStampFormat && moment(timeStampFormat).format("DD/MM/YYYY h:mmA");
};

export const convertNumber = (value) => {
  const number = Number(value);
  return Number.isNaN(number) ? 0 : number;
};

export const convertDate = (timeStampFormat) => {
  return timeStampFormat && moment(timeStampFormat).format("DD/MM/YYYY");
};

export const convertGender = (number) => {
  return GENDERS.find((gender) => gender.id === number)?.key;
};

export const formatDate = (date) => {
  let dateMoment;
  try {
    dateMoment = moment(date);
  } catch (e) {
    dateMoment = moment();
  }

  return dateMoment.subtract(1, "days").format();
};

export const formatTime = (time) => {
  let timeMoment;
  try {
    timeMoment = moment(time);
  } catch (e) {
    timeMoment = moment();
  }

  return timeMoment.subtract(1, "days").format("H:MM");
};

export const testImage = (url) => {
  return new Promise((resolve, reject) => {
    const tester = document.createElement("img");
    tester.addEventListener("error", () => reject("error"));
    tester.addEventListener("load", () => resolve("load"));
    tester.src = url;
  });
};

export const getEditedSingleImageInfo = (value) => {
  return {
    isEdited: value && typeof value === "object",
    isDeleted: !value,
  };
};

export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const convertDollarString = (value) => {
  const valueNumber = Number(value);

  return (Number.isNaN(valueNumber) ? 0 : valueNumber).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const convertShortDollarString = (value) => {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
  }).format(value);
};

export const convertVNDString = (value) => {
  const valueNumber = Number(value);

  return (Number.isNaN(valueNumber) ? 0 : valueNumber).toLocaleString("vi-VN", {
    style: "currency",
    currency: "VND",
  });
};

export const convertRawCategoriesToTreeDataFormat = ({
  categories = [],
  parent = null,
}) =>
  categories
    .filter((item) => item.parent === parent)
    .map(({ name, id }) => ({
      title: name,
      value: id,
      children: convertRawCategoriesToTreeDataFormat({
        categories,
        parent: id,
      }),
    }));

export const CryptoJsAES = {
  decode: (encrypted) =>
    CryptoJS.AES.decrypt(
      encrypted?.replaceAll("-", "/"),
      process.env.REACT_APP_CRYPTO_JS_SECRET ?? ""
    ).toString(CryptoJS.enc.Utf8),
  encode: (data) =>
    CryptoJS.AES.encrypt(data, process.env.REACT_APP_CRYPTO_JS_SECRET ?? "")
      .toString()
      ?.replaceAll("/", "-"),
};

export const filterByText = ({ value, input }) =>
  (value ?? "").toLowerCase().includes(input.toLowerCase());

export const formatUserName = ({ firstName, lastName, username }) => {
  if (!firstName && !lastName) {
    return username;
  }
  return `${firstName ?? ""} ${lastName ?? ""} `;
};

export const getContentFromNamePath = ({ value, namePath }) => {
  if (!namePath) return null;
  else if (typeof namePath === "string") return value?.[namePath];
  else return namePath.reduce((prev, curr) => prev?.[curr], value);
};

export const getSelectUserCommonProps = ({ users = [] }) => {
  const options = users.map(
    ({
      id,
      firstName,
      lastName,
      username,
      s3Profile,
      phoneNumber,
      email,
      fullName,
    }) => {
      const name =
        firstName || lastName
          ? `${firstName ?? ""} ${lastName ?? ""}`
          : fullName ?? username ?? "";
      return {
        label: (
          <UserInfoItem
            id={id}
            s3Profile={s3Profile}
            fullName={name}
            isSizeSmall={true}
            isShowContactInfo={true}
            email={email}
          />
        ),
        value: id,
        name,
        phoneNumber,
        email,
      };
    }
  );
  const filterOption = (input, option) =>
    filterByText({
      value: option?.name,
      input,
    }) ||
    filterByText({ value: option?.code, input }) ||
    filterByText({
      value: option?.phoneNumber,
      input,
    }) ||
    filterByText({ value: option?.email, input });
  return {
    options,
    filterOption,
    optionLabelProp: "name",
  };
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getFileInfoFromFileLink = (fileLink = "") => {
  const name = fileLink.split("_").pop();
  const fileExtension = name.split(".").pop();
  let type;
  switch (fileExtension) {
    case "doc":
      type = APP_UPLOAD_FILE_MIME_TYPES.WORD[0];
      break;
    case "pdf":
      type = APP_UPLOAD_FILE_MIME_TYPES.PDF[0];
      break;
    case "xls":
    case "xlsx":
    case "ods":
      type = APP_UPLOAD_FILE_MIME_TYPES.EXCEL[0];
      break;
    default:
      type = APP_UPLOAD_FILE_MIME_TYPES.IMAGE[0];
  }
  return {
    name,
    type,
  };
};

export const convertTableSortOrder = (tableSortOrder) => {
  switch (tableSortOrder) {
    case "ascend":
      return SortType.ASC;
    case "descend":
      return SortType.DESC;
    default:
      return undefined;
  }
};

export const tableSorterChangeHandle = ({ setParams, sorters }) => {
  if (sorters.order) {
    setParams((prev) => ({
      ...prev,
      sortBy: sorters.columnKey,
      order: convertTableSortOrder(sorters.order),
      page: PageDefault,
    }));
  } else {
    setParams((prev) => {
      return {
        ...prev,
        sortBy: DEFAULT_COLUMN_SORT,
        order: SortType.DESC,
      };
    });
  }
};
export const ConvertStatusPO = ({ status }) => {
  switch (status?.toLowerCase()) {
    case PO_STATUS.CREATING:
      return (
        <Badge status="warning" text={<IntlMessages id="app.po.creating" />} />
      );
    case PO_STATUS.CREATING_VERIFIED:
      return (
        <Badge
          status="warning"
          text={<IntlMessages id="app.po.creatingVerified" />}
        />
      );
    case PO_STATUS.MODIFYING:
      return (
        <Badge status="warning" text={<IntlMessages id="app.po.modifying" />} />
      );
    case PO_STATUS.MODIFYING_VERIFIED:
      return (
        <Badge status="warning" text={<IntlMessages id="app.po.modifyingVerified" />} />
      );
    case PO_STATUS.PROCESSING:
      return (
        <Badge
          status="warning"
          text={<IntlMessages id="app.po.processing" />}
        />
      );
    case PO_STATUS.CANCELLED:
      return (
        <Badge status="warning" text={<IntlMessages id="app.po.cancelled" />} />
      );
    case PO_STATUS.ACTIVE:
      return (
        <Badge status="success" text={<IntlMessages id="app.po.active" />} />
      );
    case PO_STATUS.REJECTED:
      return (
        <Badge status="error" text={<IntlMessages id="app.po.rejected" />} />
      );
    case PO_STATUS.EXPIRED:
      return (
        <Badge status="default" text={<IntlMessages id="app.po.expired" />} />
      );
    case PO_STATUS.DRAFT:
      return (
        <Badge status="default" text={<IntlMessages id="app.po.draft" />} />
      );

    default:
      return <></>;
  }
};

export const convertBase64ToFile = (base64) => {
  const type = base64.split(";")[0].split("/")[1];
  const byteString = atob(base64.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: `image/${type}` });
  const file = new File([blob], `image.${type}`, { type: `image/${type}` });
  return file;
};

export const generateUuid = () => {
  const timestamp = new Date().getTime();
  const randomNum = Math.floor(Math.random() * 10000);

  return `${timestamp}${randomNum}`;
};

export const getCurrentOrderPlan = ({ orderPlans = [] }) => {
  const now = new Date();
  return orderPlans.find(({ activedAt, expiredTime }) => {
    const activedAtDate = new Date(activedAt);
    const expiredTimeDate = new Date(expiredTime);
    return activedAtDate <= now && now <= expiredTimeDate;
  });
};

export const getCreateDeleteIds = ({ oldIds = [], newIds = [] }) => {
  const deleteIds = oldIds.filter((id) => !newIds.includes(id));
  const createIds = newIds.filter((id) => !oldIds.includes(id));
  return { deleteIds, createIds };
};

export const getCreateDeleteFiles = ({ oldFiles = [], newFiles = [] }) => {
  const deleteFiles = oldFiles.filter(
    (file) => !newFiles.find((f) => f.uid === file.uid)
  );
  const createFiles = newFiles.filter((file) => file.originFileObj);

  return { deleteFiles, createFiles };
};

export const convertOrdinalSuffix = (rank) => {
  const j = rank % 10,
    k = rank % 100;
  if (j === 1 && k !== 11) {
    return rank + "st";
  }
  if (j === 2 && k !== 12) {
    return rank + "nd";
  }
  if (j === 3 && k !== 13) {
    return rank + "rd";
  }
  return rank + "th";
};

export const downLoadFileFromResponse = (response, fileName) => {
  const outputFilename = `${fileName.toLocaleLowerCase()}.xlsx`;
  const url = URL.createObjectURL(new Blob([response]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", outputFilename);
  document.body.appendChild(link);
  link.click();
  link.remove();
  document.body.removeChild(link);
};

export const getTitleContentLog = ({
  // entityName,
  entityId,
  userId,
  interactType,
}) => {
  return {
    title: TITLE_CONTENT_LOGS?.[interactType]?.title({ userId }),
    content: TITLE_CONTENT_LOGS?.[interactType]?.content({
      resellerId: entityId,
      leadId: entityId,
      orderId: entityId,
      ticketId: entityId,
      userId,
    }),
  };
};

export const TITLE_CONTENT_LOGS = {
  CREATE_POTENTIAL_RESELLER: {
    title: () => "NEW RESELLER REGISTERED",
    content: ({ resellerId }) => `Got a new reseller with id ${resellerId}.`,
  },
  CREATE_TICKET: {
    title: ({ userId }) =>
      `GOT A NEW TICKET FROM ${userId ? "ADMIN" : "RESELLER"}`,
    content: ({ ticketId, userId }) =>
      `Got a new ticket from ${
        userId ? "admin." : `reseller with id ${ticketId}.`
      }`,
  },
  APPROVE_LEAD: {
    title: () => "THE LEAD HAS BEEN APPROVED",
    content: ({ leadId }) =>
      `Lead with id ${leadId} has been approved by admin.`,
  },
  REJECT_LEAD: {
    title: () => "THE LEAD WAS REJECTED",
    content: ({ leadId }) =>
      `Lead with id ${leadId} has been rejected by admin.`,
  },
  CREATE_LEAD: {
    title: ({ userId }) =>
      `GOT A NEW LEAD FROM ${userId ? "ADMIN" : "RESELLER"}`,
    content: ({ userId, leadId }) =>
      `Got a new lead ${
        userId
          ? `with id ${leadId} from admin.`
          : `from reseller with lead id ${leadId}.`
      }`,
  },
  VERIFIED_ORDER: {
    title: () => "THE PO HAS BEEN VERIFIED FROM SUB ADMIN",
    content: ({ orderId }) =>
      `Order with id ${orderId} has been verified by sub admin.`,
  },
  CREATE_ORDER: {
    title: () => "GOT A NEW ORDER FROM RESELLER",
    content: ({ userId }) => `Got a new order from reseller with id ${userId}.`,
  },
  APPROVE_ORDER: {
    title: () => "THE PO HAS BEEN APPROVED",
    content: ({ orderId }) =>
      `Order with id ${orderId} has been approved by admin.`,
  },
  REJECT_ORDER: {
    title: () => "THE PO WAS REJECTED",
    content: ({ orderId }) =>
      `Order with id ${orderId} has been rejected by admin.`,
  },
};

export const removeDuplicateFilterValue = (array) => {
  const seen = new Set();
  return array.filter((item) => {
    const duplicate = seen.has(item.value);
    seen.add(item.value);
    return !duplicate;
  });
};
