import { URL_API } from "@solidandsoft/services/endpoint";
import jwtAxios from "@solidandsoft/services/auth/jwt-auth/jwt-api";
import { useQuery } from "react-query";

const getNotifications = (params) =>
  jwtAxios.get(URL_API.CRM_NOTIFICATIONS.GET_NOTIFICATIONS, { params });

const useGetNotifications = (params) =>
  useQuery([URL_API.CRM_NOTIFICATIONS.GET_NOTIFICATIONS, params], () =>
    getNotifications(params)
  );

export default useGetNotifications;
