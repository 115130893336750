import IntlMessages from "@solidandsoft/utility/IntlMessages";
import { ConvertStatusPO } from "../../../@solidandsoft/utility/Utils";

export const ORDER_STATUS_ACTION = {
  SEND_REQUEST_RESELLER: "SEND_REQUEST_RESELLER",
  APPROVE: "APPROVE",
  APPROVE_MANUALLY: "APPROVE_MANUALLY",
  REJECT: "REJECT",
  SEND_REQUEST_CANCEL: "SEND_REQUEST_CANCEL",
  SEND_REQUEST_MODIFYING: "SEND_REQUEST_MODIFYING",
  VERIFIED: "VERIFIED",

  CREATING_VERIFIED: "CREATING_VERIFIED",
  MODIFYING_VERIFIED: "MODIFYING_VERIFIED",
};

export const ORDER_STATUS = {
  CREATING: "CREATING",
  DRAFT: "DRAFT",
  ACTIVE: "ACTIVE",
  REJECTED: "REJECTED",
  CANCELLING: "CANCELLING",
  MODIFYING: "MODIFYING",
  PROCESSING: "PROCESSING",
  CANCELLED: "CANCELLED",
  EXPIRED: "EXPIRED",
  CREATING_VERIFIED: "CREATING_VERIFIED",
  MODIFYING_VERIFIED: "MODIFYING_VERIFIED",
};

export const RoleIdEnum = {
  SUPER_ADMIN: "1",
  SUB_ADMIN: "3",
  RESELLER: "2",
  SUB_RESELLER: "4",
};

export const ORDER_STATUS_OPTIONS = [
  {
    value: ORDER_STATUS.DRAFT,
    label: ConvertStatusPO({ status: ORDER_STATUS.DRAFT }),
  },
  {
    value: ORDER_STATUS.CREATING,
    label: ConvertStatusPO({ status: ORDER_STATUS.CREATING }),
  },

  {
    value: ORDER_STATUS.CANCELLED,
    label: ConvertStatusPO({ status: ORDER_STATUS.CANCELLED }),
  },
  {
    value: ORDER_STATUS.MODIFYING,
    label: ConvertStatusPO({ status: ORDER_STATUS.MODIFYING }),
  },
  // {
  //   value: ORDER_STATUS.PROCESSING,
  //   label: ConvertStatusPO({ status: ORDER_STATUS.PROCESSING }),
  // },
  // {
  //   value: ORDER_STATUS.CANCELLED,
  //   label: ConvertStatusPO({ status: ORDER_STATUS.CANCELLED }),
  // },
  {
    value: ORDER_STATUS.ACTIVE,
    label: ConvertStatusPO({ status: ORDER_STATUS.ACTIVE }),
  },
  {
    value: ORDER_STATUS.EXPIRED,
    label: ConvertStatusPO({ status: ORDER_STATUS.EXPIRED }),
  },
  {
    value: ORDER_STATUS.REJECTED,
    label: ConvertStatusPO({ status: ORDER_STATUS.REJECTED }),
  },
];

export const CREATE_PO_TYPE = {
  SEND_REQUEST: "send",
  DRAFT: "draft",
  APPROVE_MANUALLY: "manual",
  APPROVE_AUTO: "auto",
  SEND_REQUEST_MODIFYING: "send-modifying",
  APPROVE_MODIFY_MANUALLY: "manual-modify",
  APPROVE_MODIFY_AUTO: "auto-modify",
};

export const EDIT_PO_ACTIONS = {
  SAVE: "SAVE",
  REQUEST_MODIFYING: "REQUEST_MODIFYING",
  MODIFY: "MODIFY",
  MODIFY_MANUALLY: "MODIFY_MANUALLY",
};

export const PRICE_CYCLE_COLOR = {
  MONTHLY: "blue",
  YEARLY: "green",
};

export const PRICE_CYCLE_OPTIONS = Object.keys(PRICE_CYCLE_COLOR).map(
  (key) => ({
    value: key,
    label: <IntlMessages id={`common.${key?.toLowerCase()}`} />,
    text: <IntlMessages id={`common.${key?.toLowerCase()}`} />,
  })
);
