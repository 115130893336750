import { URL_API } from "@solidandsoft/services/endpoint";
import jwtAxios from "@solidandsoft/services/auth/jwt-auth/jwt-api";
import { useQuery } from "react-query";

const getNotificationById = (id) =>
  jwtAxios.get(`${URL_API.CRM_NOTIFICATIONS.GET_NOTIFICATION}/${id}`);

const useGetNotificationById = (id) =>
  useQuery([URL_API.CRM_NOTIFICATIONS.GET_NOTIFICATION, id], () =>
    getNotificationById(id)
  );

export default useGetNotificationById;
