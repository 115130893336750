import { CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";
import toast from "react-hot-toast";

export const TOAST_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
};

const customMessage = {
  success: (message) =>
    toast(
      () => (
        <Space>
          <CheckOutlined style={{ color: "#fff" }} />
          <Typography style={{ color: "#fff", fontSize: 15 }}>
            {message}
          </Typography>
        </Space>
      ),
      {
        style: {
          backgroundColor: "#52c51a",
          borderRadius: "8px",
        },
      }
    ),
  error: (message) =>
    toast(
      () => (
        <Space>
          <CloseCircleOutlined style={{ color: "#fff", fontSize: 15 }} />
          <Typography style={{ color: "#fff" }}>{message}</Typography>
        </Space>
      ),
      {
        style: {
          backgroundColor: "#ff4d4f",
          borderRadius: "8px",
        },
      }
    ),
};
export default customMessage;
