import IntlMessages from "@solidandsoft/utility/IntlMessages";
import { Tag } from "antd";

const useGetViewAddOnsFieldConfig = () => {
  return [
    {
      title: <IntlMessages id="app.notifications.title" />,
      dataIndex: "title",
    },
    {
      title: <IntlMessages id="app.notifications.cta" />,
      dataIndex: "cta",
      render: (value) =>
        value && (
          <a href={value} target="_blank" rel="noopener noreferrer">
            <Tag color="blue">{value}</Tag>
          </a>
        ),
    },
    {
      title: <IntlMessages id="app.notifications.content" />,
      dataIndex: "content",
      col: 2,
    },
  ];
};

export default useGetViewAddOnsFieldConfig;
