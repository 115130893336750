import { lazy } from "react";
import { PERMISSIONS } from "shared/constants/AppEnums";

const Users = lazy(() => import("./Users"));
const AddOns = lazy(() => import("./AddOns"));
const Products = lazy(() => import("./Products"));
const PurchasingOrder = lazy(() => import("./PurchasingOrder"));
const Registrations = lazy(() => import("./ResellerRegistations"));
const Tickets = lazy(() => import("./Tickets"));
const Leads = lazy(() => import("./Leads"));
const Notifications = lazy(() => import("./Notifications"));
const ResellerProgress = lazy(() => import("./ResellerProgress"));
const UserInteracts = lazy(() => import("./UserInteracts"));
const Discounts = lazy(() => import("./Discounts"));
const News = lazy(() => import("./News"));

export const RESELLER_PROGRESS_PATH = "/crm/reseller-review";
export const crmConfig = [
  {
    path: "/crm/products/add-ons",
    element: <AddOns />,
    scopes: [
      PERMISSIONS.VIEW_BASE_ADD_ON,
      PERMISSIONS.VIEW_ALL_ADD_ON,
      PERMISSIONS.CREATE_ADD_ON,
      PERMISSIONS.EDIT_ADD_ON,
      PERMISSIONS.DELETE_ADD_ON,
    ],
  },
  {
    path: "/crm/products",
    element: <Products />,
    scopes: [
      PERMISSIONS.VIEW_BASE_PRODUCT,
      PERMISSIONS.VIEW_ALL_PRODUCT,
      PERMISSIONS.CREATE_PRODUCT,
      PERMISSIONS.EDIT_PRODUCT,
      PERMISSIONS.DELETE_PRODUCT,
    ],
  },
  {
    path: "/crm/users",
    element: <Users />,
    scopes: [
      PERMISSIONS.CREATE_USER,
      PERMISSIONS.EDIT_USER,
      PERMISSIONS.DELETE_USER,
    ],
  },
  {
    path: "/crm/purchasing-order",
    element: <PurchasingOrder />,
    scopes: [
      PERMISSIONS.CREATE_ORDER,
      PERMISSIONS.EDIT_ORDER,
      PERMISSIONS.DELETE_ORDER,
      PERMISSIONS.VERIFY_ORDER,
    ],
  },
  {
    path: "/crm/reseller-registrations",
    element: <Registrations />,
    scopes: [
      PERMISSIONS.CREATE_RESELLER,
      PERMISSIONS.EDIT_RESELLER,
      PERMISSIONS.CANCEL_RESELLER,
    ],
  },
  {
    path: "/crm/tickets",
    element: <Tickets />,
    scopes: [
      PERMISSIONS.EDIT_TICKET,
      PERMISSIONS.CREATE_TICKET,
      PERMISSIONS.DELETE_TICKET,
    ],
  },
  {
    path: "/crm/leads",
    element: <Leads />,
    scopes: [
      PERMISSIONS.EDIT_LEAD,
      PERMISSIONS.CREATE_LEAD,
      PERMISSIONS.DELETE_LEAD,
    ],
  },
  {
    path: "/crm/notifications",
    element: <Notifications />,
    scopes: [
      PERMISSIONS.CREATE_NOTIFICATION,
      PERMISSIONS.EDIT_NOTIFICATION,
      PERMISSIONS.DELETE_NOTIFICATION,
    ],
  },
  {
    path: "/crm/logs",
    element: <UserInteracts />,
  },
  {
    path: RESELLER_PROGRESS_PATH,
    element: <ResellerProgress />,
  },
  {
    path: "/crm/discounts",
    element: <Discounts />,
  },
  {
    path: "/crm/news",
    element: <News />,
  },
];
