import IntlMessages from "@solidandsoft/utility/IntlMessages";
import UserActionInfoItem from "@solidandsoft/core/AppViewItems/UserActionInfoItem";
import { TIME_RANGE_FILER_ENUM } from "./AppEnums";
import moment from "moment";

export const initialUrl = "/dashboards"; // this url will open after login
export const PageDefault = 1;
export const TakeDefault = 10;

export const TABLE_ACTION_COLUMN_KEY = "action";

export const UserActionColumns = [
  {
    title: <IntlMessages id="common.createBy" />,
    key: "createBy",
    width: 220,
    align: "center",
    render: (data) => {
      const { createdAt, createdUser } = data ?? {};
      const { id, username, s3Profile, email, fullName } = createdUser ?? {};

      return (
        <UserActionInfoItem
          id={id}
          s3Profile={s3Profile}
          time={createdAt}
          displayName={fullName || email || username}
        />
      );
    },
  },
  {
    title: <IntlMessages id="common.updateBy" />,
    key: "updateBy",
    align: "center",
    width: 220,
    render: (data) => {
      const { updatedAt, updatedUser } = data;
      if (!updatedUser) return null;
      const { id, username, s3Profile, email, fullName } = updatedUser;
      return (
        <UserActionInfoItem
          id={id}
          s3Profile={s3Profile}
          time={updatedAt}
          displayName={fullName || email || username}
        />
      );
    },
  },
];

export const TIME_RANGE_FILTER = new Map([
  [
    TIME_RANGE_FILER_ENUM.THIS_WEEK,
    {
      label: "This week",
      value: {
        startDate: moment().startOf("week"),
        endDate: moment(),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.LAST_WEEK,
    {
      label: "Last week",
      value: {
        startDate: moment().subtract(1, "week").startOf("week"),
        endDate: moment().subtract(1, "week").endOf("week"),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.THIS_MONTH,
    {
      label: "This month",
      value: {
        startDate: moment().startOf("month"),
        endDate: moment(),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.LAST_MONTH,
    {
      label: "Last month",
      value: {
        startDate: moment().subtract(1, "month").startOf("month"),
        endDate: moment().subtract(1, "month").endOf("month"),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.THIS_YEAR,
    {
      label: "This year",
      value: {
        startDate: moment().startOf("year"),
        endDate: moment(),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.LAST_YEAR,
    {
      label: "Last year",
      value: {
        startDate: moment().subtract(1, "year").startOf("year"),
        endDate: moment().subtract(1, "year").endOf("year"),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.CUSTOM,
    {
      label: "Custom time range",
    },
  ],
]);

export const MONTHS_IN_YEAR = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const ROLE_MEMBER_NAME = "Member";

export const PASSWORD_RULES =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/;

export const USERNAME_RULE = /^[a-zA-Z0-9]{8,}$/;

export const COMMON_FORM_ITEM_RULE = {
  REQUIRED: {
    required: true,
    message: <IntlMessages id="common.required" />,
  },
  PASSWORD: {
    pattern: PASSWORD_RULES,
    message: <IntlMessages id="userProfile.incorrectFormatPassword" />,
  },
  EMAIL: {
    type: "email",
    message: <IntlMessages id="common.invalidEmail" />,
  },
  RE_ENTER_PASSWORD:
    ({ messageIntl, passwordFieldName }) =>
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue(passwordFieldName) === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error(messageIntl["userProfile.passwordNotMatch"])
        );
      },
    }),
  USERNAME: {
    pattern: USERNAME_RULE,
    message:
      "Username must contain 8 or more characters, have not special and space character",
  },
  PHONE: (messageIntl) => ({
    validator(_, value) {
      if (
        !value ||
        (value?.length >= 10 &&
          value?.length <= 11 &&
          !Number.isNaN(Number(value)))
      ) {
        return Promise.resolve();
      }

      return Promise.reject(new Error(messageIntl["common.invalidPhone"]));
    },
  }),
};

export const APP_UPLOAD_FILE_TYPE = {
  PDF: "PDF",
  IMAGE: "IMAGE",
  WORD: "WORD",
  EXCEL: "EXCEL",
};

// docs: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const APP_UPLOAD_FILE_MIME_TYPES = {
  PDF: ["application/pdf", "pdf"],
  WORD: [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "docx",
  ],
  IMAGE: [
    "image/jpeg",
    "image/avif",
    "image/bmp",
    "image/gif",
    "image/vnd.microsoft.icon",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "jpeg",
    "jpg",
    "avif",
    "bmp",
    "gif",
    "vnd.microsoft.icon",
    "png",
    "svg+xml",
    "tiff",
    "webp",
  ],
  EXCEL: [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.oasis.opendocument.spreadsheet",
    "xlsx",
  ],
};

export const DEFAULT_COLUMN_SORT = "id";

export const APPROVAL_STATUS = {
  PROCESSING: "PROCESSING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  TERMINATED: "TERMINATED",
};

export const APPROVAL_POTENTIAL_STATUS = {
  PROCESSING: "PROCESSING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  TERMINATED: "TERMINATED",
  VIEW_ALL: "VIEW_ALL",
};

export const APPROVAL_STATUS_COLOR = {
  PROCESSING: "yellow",
  APPROVED: "green",
  REJECTED: "red",
  TERMINATED: "orange",
  VIEW_ALL: "blue",
};

export const APPROVAL_BUTTON_OPTIONS = [
  {
    key: APPROVAL_POTENTIAL_STATUS.VIEW_ALL,
    title: (
      <IntlMessages
        id={`app.status.${APPROVAL_POTENTIAL_STATUS.VIEW_ALL?.toLowerCase()}`}
      />
    ),
    keyCount: "all",
    backgroundColor: "#e6f4ff",
    color: "#1677ff",
    border: "#91caff",
  },
  {
    key: APPROVAL_POTENTIAL_STATUS.PROCESSING,
    title: (
      <IntlMessages
        id={`app.status.${APPROVAL_POTENTIAL_STATUS.PROCESSING?.toLowerCase()}`}
      />
    ),
    keyCount: "processing",
    backgroundColor: "#fffbe6",
    color: "#faad14",
    border: "#ffe58f",
  },
  {
    key: APPROVAL_POTENTIAL_STATUS.APPROVED,
    title: (
      <IntlMessages
        id={`app.status.${APPROVAL_POTENTIAL_STATUS.APPROVED?.toLowerCase()}`}
      />
    ),
    keyCount: "actived",
    backgroundColor: "#f6ffed",
    color: "#52c41a",
    border: "#b7eb8f",
  },
  {
    key: APPROVAL_POTENTIAL_STATUS.TERMINATED,
    title: (
      <IntlMessages
        id={`app.status.${APPROVAL_POTENTIAL_STATUS.TERMINATED?.toLowerCase()}`}
      />
    ),
    keyCount: "terminated",
    backgroundColor: "#fff7e6",
    color: "#d46b08",
    border: "#ffd591",
  },
  {
    key: APPROVAL_POTENTIAL_STATUS.REJECTED,
    title: (
      <IntlMessages
        id={`app.status.${APPROVAL_POTENTIAL_STATUS.REJECTED?.toLowerCase()}`}
      />
    ),
    keyCount: "rejected",
    backgroundColor: "#fff1f0",
    color: "#ff4d4f",
    border: "#ffccc7",
  },
];

export const APPROVAL_STATUS_OPTIONS = Object.keys(APPROVAL_STATUS).map(
  (status) => ({
    value: status,
    label: <IntlMessages id={`app.status.${status?.toLowerCase()}`} />,
    text: <IntlMessages id={`app.status.${status?.toLowerCase()}`} />,
  })
);

export const PRICE_CYCLE = {
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY",
};

export const PRICE_CYCLE_COLOR = {
  MONTHLY: "cyan",
  YEARLY: "orange",
};

export const PRICE_CYCLE_OPTIONS = Object.keys(PRICE_CYCLE).map((key) => ({
  value: key,
  label: <IntlMessages id={`app.priceCycle.${key?.toLowerCase()}`} />,
  text: <IntlMessages id={`app.priceCycle.${key?.toLowerCase()}`} />,
}));

export const OPEN_MODAL_PARAMS = {
  ORDER: "order-id",
  USER: "user-id",
  RESELLER: "reseller-id",
  LEAD: "lead-id",
  TICKET: "ticket-id",
  ADD_ON: "add-on-id",
  PRODUCT: "product-id",
  ROLE: "role-id",
  NOTIFICATION: "notification-id",
  DISCOUNT: "discount-id",
  NEWS: "news-id",
};

export const RESELLER_COMPANY_COUNTRY = "Việt Nam";

export const VIETNAM_PROVINCES = [
  "An Giang",
  "Bà Rịa-Vũng Tàu",
  "Bạc Liêu",
  "Bắc Kạn",
  "Bắc Giang",
  "Bắc Ninh",
  "Bến Tre",
  "Bình Dương",
  "Bình Định",
  "Bình Phước",
  "Bình Thuận",
  "Cà Mau",
  "Cần Thơ",
  "Cao Bằng",
  "Đà Nẵng",
  "Đắk Lắk",
  "Đắk Nông",
  "Điện Biên",
  "Đồng Nai",
  "Đồng Tháp",
  "Gia Lai",
  "Hà Giang",
  "Hà Nam",
  "Hà Nội",
  "Hà Tĩnh",
  "Hải Dương",
  "Hải Phòng",
  "Hậu Giang",
  "Hòa Bình",
  "Hồ Chí Minh (Saigon)",
  "Hưng Yên",
  "Khánh Hòa",
  "Kiên Giang",
  "Kon Tum",
  "Lai Châu",
  "Lâm Đồng",
  "Lạng Sơn",
  "Lào Cai",
  "Long An",
  "Nam Định",
  "Nghệ An",
  "Ninh Bình",
  "Ninh Thuận",
  "Phú Thọ",
  "Phú Yên",
  "Quảng Bình",
  "Quảng Nam",
  "Quảng Ngãi",
  "Quảng Ninh",
  "Quảng Trị",
  "Sóc Trăng",
  "Sơn La",
  "Tây Ninh",
  "Thái Bình",
  "Thái Nguyên",
  "Thanh Hóa",
  "Thừa Thiên-Huế",
  "Tiền Giang",
  "Trà Vinh",
  "Tuyên Quang",
  "Vĩnh Long",
  "Vĩnh Phúc",
  "Yên Bái",
];

export const RESELLER_COMPANY_INDUSTRIES_OPTIONS = [
  "app.registrations.manufacturing",
  "app.registrations.education",
  "app.registrations.retail",
  "app.registrations.media",
  "app.registrations.IT",
  "app.registrations.marketing",
  "app.registrations.others",
];
//
export const RESELLER_COMPANY_EMPLOYEES_AMOUNT_OPTIONS = [
  "1",
  "2-10",
  "11-50",
  "51-99",
  "100-499",
  "500-1999",
  "2000+",
];

export const RESELLER_COMPANY_ANNUAL_REVENUE_RANGES = [
  "< $250K",
  "$250K - $1M",
  "$1M - $5M",
  "> $5M",
];

export const RESELLER_COMPANY_STRUCTURES_OPTIONS = [
  "app.registrations.publicListed",
  "app.registrations.independentBranch",
  "app.registrations.jointStockCompany",
  "app.registrations.soleProprietorship",
  "app.registrations.privateLimited",
  "app.registrations.partnership",
];

export const RESELLER_FILE_PATH = "reseller_files";
