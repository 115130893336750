import { getUserFromJwtAuth } from "./helper/AuthHelper";
import {
  useJWTAuth,
  useJWTAuthActions,
} from "../services/auth/jwt-auth/JWTAuthProvider";

export const useAuthUser = () => {
  const {
    user,
    isAuthenticated,
    isLoading,
    getAuthUser,
    isApproved,
    reason,
    currentStatus,
  } = useJWTAuth();
  return {
    isLoading,
    isAuthenticated,
    user: getUserFromJwtAuth(user),
    getAuthUser,
    isApproved,
    reason,
    currentStatus,
  };
};

export const useAuthMethod = () => {
  const { signInUser, signUpUser, logout } = useJWTAuthActions();

  return {
    signInUser,
    logout,
    signUpUser,
  };
};
