import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { Card, Col, Modal } from "antd";
import { AppRowContainer } from "../../index";
import { getContentFromNamePath } from "../../utility/Utils";
import "./index.style.less";

const AppViewModal = forwardRef(
  ({ data, fieldsConfig, title, width, colPerRow = 2, footer }, ref) => {
    const [isVisible, setIsVisible] = useState(false);
    const openModal = useCallback(() => {
      setIsVisible(true);
    }, [setIsVisible]);
    const closeModal = useCallback(() => setIsVisible(false), [setIsVisible]);
    useImperativeHandle(
      ref,
      () => ({
        openModal,
        closeModal,
      }),
      [openModal, closeModal]
    );
    const renderItemContent = useCallback(
      (render, dataIndex) => {
        const dataIndexContent = getContentFromNamePath({
          value: data,
          namePath: dataIndex,
        });
        let itemContent;
        if (render)
          itemContent = render(dataIndex ? dataIndexContent : data, data);
        else itemContent = dataIndexContent;
        return <span>{itemContent}</span>;
      },
      [data]
    );
    return (
      <Modal
        className="container"
        open={isVisible}
        onCancel={closeModal}
        footer={footer ?? null}
        width={width ?? 700}
        title={title}
        centered
      >
        <Card
          bordered={false}
          style={{
            overflowY: "auto",
            maxHeight: "80vh",
          }}
        >
          {data && (
            <AppRowContainer gutter={16}>
              {fieldsConfig?.map((item, index) => {
                const { render, dataIndex, title, col = 1 } = item;
                return (
                  <Col key={index} xs={24} sm={(24 * col) / colPerRow}>
                    <AppRowContainer>
                      <Col className="title" xs={24}>
                        {title}
                      </Col>{" "}
                      <Col className="content" xs={24}>
                        {renderItemContent(render, dataIndex)}
                      </Col>
                    </AppRowContainer>
                  </Col>
                );
              })}
            </AppRowContainer>
          )}
        </Card>
      </Modal>
    );
  }
);
export default AppViewModal;
