import jwtAxios from "@solidandsoft/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@solidandsoft/services/endpoint";
import { useQuery } from "react-query";

const getUnreadNoti = async () =>
  jwtAxios.get(URL_API.CRM_NOTIFICATIONS.GET_UNREAD);

const useGetUnReadNoti = () =>
  useQuery([URL_API.CRM_NOTIFICATIONS.GET_UNREAD], () => getUnreadNoti());

export default useGetUnReadNoti;
